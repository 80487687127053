<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p>
        Sort each of the following elements by
        <span class="text-bold">electron affinity</span> from
        <span class="text-bold">smallest to largest</span>:
      </p>

      <drag-to-rank-input
        v-model="inputs.input1"
        :items="itemList"
        class="mb-0"
        style="max-width: 400px"
        prepend-text="Largest"
        append-text="Smallest"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import DragToRankInput from '@/tasks/components/inputs/DragToRankInput';

export default {
  name: 'Question35',
  components: {
    DragToRankInput,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        input1: [],
      },
      itemList: ['$\\text{F}$', '$\\text{Mg}$', '$\\text{S}$'],
    };
  },
};
</script>
